<div class="layout-container flex flex-column" [ngClass]="containerClass">
  <app-sidebar></app-sidebar>

  <div class="layout-content-wrapper flex-1 flex flex-column">
    <div class="flex-1">
      <app-topbar></app-topbar>
      <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
      <div class="layout-content">
        <router-outlet></router-outlet>
      </div>
    </div>

    <ng-container *ngIf="environment.footer">
      <footer class="mt-1 flex justify-content-between align-items-center">
        <div class="flex align-items-center gap-2">
          <span> {{ "environment." + environment.env | translate }} </span>
          <span> - </span>
          <span>v{{environment.version}}</span>
        </div>
        <span> Made in Bavaria 🥨 by FastRocket </span>
      </footer>
    </ng-container>
  </div>

  <app-profilemenu></app-profilemenu>

  <div class="layout-mask"></div>
</div>
