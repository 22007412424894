<!-- Sender information -->
<div
  class="pb-2"
  *ngIf="msgData && file.mimetype !== 'message/rfc822' && (htmlData || plainData)">
  <span class="font-bold mr-2">{{'general.msgSender' | translate}}:</span>
  <div>
    {{msgData.senderName}} (<a
      href="'mailto:'{{msgData.sentRepresentingSmtpAddress}}"
      >{{msgData.sentRepresentingSmtpAddress}}</a
    >)
  </div>
</div>

<!-- Recipient information -->
<div
  [ngClass]="actualAttachments.length > 0 ? 'pb-2' : 'pb-4'"
  *ngIf="msgData && file.mimetype !== 'message/rfc822' &&  (htmlData || plainData)">
  <span class="font-bold mr-2">{{'general.msgRecipient' | translate}}:</span>
  <div *ngFor="let recipient of msgData.recipients">
    {{recipient.name}} (<a href="'mailto:'{{recipient.smtpAddress}}"
      >{{recipient.smtpAddress}}</a
    >)
  </div>
</div>

<!-- Sender EML information -->
<div
  class="pb-2"
  *ngIf="msgData && file.mimetype === 'message/rfc822' && (htmlData || plainData)">
  <span class="font-bold mr-2">{{'general.msgSender' | translate}}:</span>
  <div class="p-2 msg-body" [innerHTML]="msgData.from?.html"></div>
</div>

<!-- Recipient EML information -->
<div
  [ngClass]="actualAttachments.length > 0 ? 'pb-2' : 'pb-4'"
  *ngIf="msgData && file.mimetype === 'message/rfc822' &&  (htmlData || plainData)">
  <span class="font-bold mr-2">{{'general.msgRecipient' | translate}}:</span>
  <div class="p-2 msg-body" [innerHTML]="msgData.to?.html"></div>
</div>

<!-- Attachments -->
<div
  class="pb-4"
  *ngIf="msgData && (htmlData || plainData) && actualAttachments.length > 0">
  <div class="font-bold mr-2 mb-2">
    <span>{{'general.msgAttachments' | translate}}:</span>
  </div>
  <div *ngFor="let attachment of actualAttachments">
    <div class="attachment ml-2" (click)="downloadAttachment(attachment)">
      <div class="msg-attachment-label flex">
        <span class="mr-2">{{attachment.name}}</span>
        <span
          class="pi pi-download icon"
          [pTooltip]="'general.download' | translate"></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!msgData?.headers && (htmlData || plainData)">
  <div class="mr-2 mb-2">
    <span class="error-msg">{{'general.msgMissingHeaders' | translate}}</span>
  </div>
</div>

<!-- Message body -->
<div *ngIf="msgData && (htmlData || plainData)">
  <!-- if message is in html -->
  <div *ngIf="htmlData" class="p-2 msg-body" [innerHTML]="htmlData"></div>
  <!-- if message is plain text -->
  <p *ngIf="plainData" [innerHTML]="plainData | formatText"></p>
</div>

<!-- Progress spinner shown if loading === true -->
<p-progressSpinner *ngIf="loading"></p-progressSpinner>
