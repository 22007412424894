export const UserRole = {
  TechnicalAdmin: 'TechnicalAdmin',
  Admin: 'Admin',
  Dispatcher: 'Dispatcher',
  CustomerService: 'CustomerService',
  Spectator: 'Spectator'
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

export class User {
  id?: number;

  firstname?: string;

  lastname?: string;

  username?: string;

  profilePictureId?: number | null | undefined;

  email?: string;

  color?: string;

  active!: boolean;

  password?: string;

  role?: UserRole;

  createdAt?: Date;

  deletedAt?: Date;

  deletedById?: number;

  hasAppAccess!: boolean;

  hasPassword!: boolean;

  locale!: string; // ISO 639-1

  timezone!: string;

  allowAppDebugReport: boolean = false;

  constructor(config?: Partial<User>) {
    Object.assign(this, config);
  }
}
