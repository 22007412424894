/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver-es';

import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Subscription } from 'rxjs';

import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { ActionClickedResponse } from 'src/app/models/ActionClickedResponse';
import { CellChangedResponse } from 'src/app/models/CellChangedResponse';
import { GenericActionConfiguration } from 'src/app/models/GenericActionConfiguration';
import { GenericRowActionConfiguration } from 'src/app/models/GenericRowActionConfiguration';
import { GenericTableConfiguration } from 'src/app/models/GenericTableConfiguration';
import { TableFilter } from 'src/app/models/TableFilter';
import { TableFilterService } from 'src/app/services/api/filter.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { clearTableMemoryFilters } from 'src/app/utils/local-storage/table-memory.utils';
import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';

interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input() config!: GenericTableConfiguration;

  @Input() objectList: any[] = [];

  @Input() totalRecords = 0;

  @Output() objectChanged = new EventEmitter<any>();

  @Output() cellChanged = new EventEmitter<CellChangedResponse>();

  @Output() selectionChanged = new EventEmitter<any[]>();

  @Output() actionClicked = new EventEmitter<ActionClickedResponse>();

  @Output() lazyLoadTableEventFired = new EventEmitter<TableLazyLoadEvent>();

  @ViewChild('dt') public dataTable?: Table;

  globalSearchValue = '';

  styleClass = '';

  rowsPerPageOptions = environment.tableConfiguration.rowsPerPageOptions;

  scrollHeight: string = environment.tableConfiguration.scrollHeight;

  globalFilterFields: string[] = [];

  exportColumns!: ExportColumn[];

  selectedObjects: any[] = [];

  loading = false;

  environment = environment;

  lazyState?: TableLazyLoadEvent;

  saveTableFiltersEnabled = environment.saveTableFiltersEnabled;

  subscriptions = new Subscription();

  displayOverlay = false;

  tableFilters: { [s: string]: { value: any } } = {};

  selectedFilterId = 0;

  globalFilterValue = '';

  constructor(
    public translate: TranslateService,
    private tableFilterService: TableFilterService,
    private messageCenterService: MessageCenterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.generateStyleClass();
    this.generateFilterFields();
    this.generateExportColumns();

    // init global search value
    const localFilters = localStorage.getItem(
      `${this.config.table}-table-memory`
    );

    if (localFilters) {
      const { filters } = JSON.parse(localFilters);

      if (filters?.global) {
        this.globalSearchValue = filters.global.value;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['objectList'] &&
      ((this.objectList && this.objectList.length) || this.totalRecords === 0)
    ) {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private generateFilterFields(): void {
    this.globalFilterFields = this.config.columns
      .filter((col) => col.isGlobalFilterField)
      .map((col) => col.field);

    this.config.columns.forEach((col) => {
      if (!col.appendGlobalFilterFields) {
        return;
      }

      this.globalFilterFields.push(...col.appendGlobalFilterFields);
    });
  }

  loadData(event: TableLazyLoadEvent) {
    this.lazyState = event;
    this.loading = true;
    this.lazyLoadTableEventFired.emit(event);
  }

  private generateStyleClass(): void {
    if (this.config.showStripedRows) {
      this.styleClass += ' p-datatable-striped';
    }
    if (this.config.showGridLines) {
      this.styleClass += ' p-datatable-gridlines';
    }
    switch (this.config.size) {
      case 'sm':
        this.styleClass += ' p-datatable-sm';
        break;
      case 'lg':
        this.styleClass += ' p-datatable-lg';
        break;
      default:
        break;
    }
  }

  private generateExportColumns(): void {
    this.exportColumns = this.config.columns.map((col) => ({
      title: col.field,
      dataKey: col.field
    }));
  }

  onObjectChanged(object: any) {
    this.objectChanged.emit(object);
  }

  onCellChanged(object: any, field: string) {
    const changedCell = new CellChangedResponse();
    changedCell.column = field;
    changedCell.object = object;
    this.cellChanged.emit(changedCell);
  }

  onSelectionChanged(list: any) {
    this.selectionChanged.emit(list);
  }

  onColumnClicked(clickedObject: any) {
    const response = new ActionClickedResponse();
    response.object = clickedObject;
    response.action = new GenericActionConfiguration({
      returnType: 'object',
      identifier: 'columnClicked'
    });
    this.actionClicked.emit(response);
  }

  onActionClicked(
    action: GenericActionConfiguration | GenericRowActionConfiguration,
    row?: any
  ): void {
    const response = new ActionClickedResponse();
    response.action = action;
    if (action instanceof GenericActionConfiguration) {
      switch (action.returnType) {
        case 'selection':
          response.objectList = this.selectedObjects;
          break;
        case 'object':
          if (row) {
            response.object = row;
          }
          break;
        default:
          break;
      }
    }

    if (action instanceof GenericRowActionConfiguration && row) {
      response.object = row;
    }

    this.actionClicked.emit(response);
  }

  $can(action: AppAction | AppAction[] | undefined): boolean {
    const subject = this.config.permissionSubject;

    if (!subject || !action) {
      return true;
    }

    return this.authService.$can(action, subject);
  }

  saveFilterClicked(filterName: string): void {
    if (this.dataTable) {
      const tableFilter = new TableFilter();

      tableFilter.filterData = this.dataTable?.filters;
      tableFilter.table = this.config.table;
      tableFilter.filterName = filterName;

      this.subscriptions.add(
        this.tableFilterService.create(tableFilter).subscribe({
          next: (value: TableFilter) => {
            if (value) {
              this.appFilter?.reloadData();
              this.messageCenterService.showToast(
                this.translate.instant(
                  'filterComponent.actions.toasts.create.success.summary'
                ),
                this.translate.instant(
                  'filterComponent.actions.toasts.create.success.detail'
                ),
                'success'
              );
            }
          },
          error: (error) => {
            if (error) {
              this.messageCenterService.showToast(
                this.translate.instant(
                  'filterComponent.actions.toasts.create.error.summary'
                ),
                this.translate.instant(
                  'filterComponent.actions.toasts.create.error.detail'
                ),
                'error'
              );
            }
          }
        })
      );
    }
  }

  filterApplied(filter: TableFilter): void {
    this.selectedFilterId = filter.id;
    if (this.dataTable) {
      this.dataTable.filters = filter.filterData as any;
      if (!this.lazyState) {
        this.dataTable._filter();
      }
    }
    if (this.lazyState && this.lazyState.filters) {
      this.lazyState.filters = filter.filterData as any;
      this.loadData(this.lazyState);
    }
  }

  clearFilters(): void {
    this.selectedFilterId = 0;
    if (this.dataTable) {
      this.dataTable.clearFilterValues();
      if (this.lazyState) {
        this.lazyState.filters = this.dataTable.filters;
        this.loadData(this.lazyState);
      } else {
        this.dataTable.reset();
      }
    }
    if (this.config.table) {
      const tableMemoryName = `${this.config.table}-table-memory`;
      clearTableMemoryFilters(tableMemoryName);
    }
  }

  onOverlayDisplayChange() {
    this.displayOverlay = !this.displayOverlay;
  }

  exportPdf() {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default('p', 'px', 'a4');
        (doc as any).autoTable(
          this.exportColumns,
          this.selectedObjects.length > 0
            ? this.selectedObjects
            : this.objectList
        );
        doc.save('export.pdf');
      });
    });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(
        this.selectedObjects.length > 0 ? this.selectedObjects : this.objectList
      );
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      this.saveAsExcelFile(excelBuffer, '');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(
      data,
      `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`
    );
  }
}
