import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import {
  NgxExtendedPdfViewerComponent,
  NgxExtendedPdfViewerService
} from 'ngx-extended-pdf-viewer';
import { Table } from 'primeng/table';
import { first, map, Observable, Subscription } from 'rxjs';

import { FilterComponent } from 'src/app/components/misc/generic-table/filter/filter.component';
import { FileDto } from 'src/app/models/FileDto';
import { FormType, HiveForm } from 'src/app/models/HiveForm';
import { TableFilter } from 'src/app/models/TableFilter';
import { Ticket } from 'src/app/models/Ticket';
import { User } from 'src/app/models/User';
import { PdfService } from 'src/app/services/api/pdf.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessageCenterService } from 'src/app/services/message-center.service';
import { Severity } from 'src/app/types/misc/Severity';
import { clearTableMemoryFilters } from 'src/app/utils/local-storage/table-memory.utils';
import { hasPermissionToUnlockFiles } from 'src/app/utils/role/role.utils';
import { AppAction } from 'src/config/authorization.config';
import { environment } from 'src/environments/environment';
import { blobToBase64 } from 'src/utils/blob-to-base64';

@Component({
  selector: 'app-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class FormTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pdfViewer') public pdfViewer?: NgxExtendedPdfViewerComponent;

  @ViewChild('formTable') public formTable?: Table;

  @ViewChild('appFilter') appFilter?: FilterComponent;

  @Input({ required: true }) ticket!: Ticket;

  @Input() showTechnicianColumn = true;

  @Input() showCreatedAtColumn = false;

  @Input() showStatusColumn = true;

  @Input() showCompletedAtColumn = true;

  @Input() deadlineEditable = false;

  @Input() showEditAction = true;

  @Input() showPdfAction = true;

  @Input() showDeleteAction = true;

  @Input() showCreateAction = true;

  @Input() showGlobalSearch = true;

  @Input() selectionDisabled = true;

  @Input() showSelectedFormsOnly = false;

  @Input() smallTable = false;

  @Input() isSelectable = false;

  @Input() selectedForms: HiveForm[] = [];

  @Input() currentAppointmentId: number | null = null;

  @Input() showTechnicianCalendar = true;

  @Output() handleCreateForm = new EventEmitter<void>();

  @Output() handleUpdateForm = new EventEmitter<HiveForm>();

  @Output() handleDeleteForm = new EventEmitter<HiveForm>();

  @Output() selectedFormsChange = new EventEmitter<HiveForm[]>();

  @Output() deadlineChange = new EventEmitter<HiveForm>();

  @Output() ticketUpdated = new EventEmitter<Ticket>();

  public blob: Blob | undefined;

  forms: HiveForm[] = [];

  isLoading = false;

  tableConfig = environment.tableConfiguration;

  apiUrl = environment.apiUrl;

  isFilterApplied = false;

  activeFilter: number | null = null;

  displayFilterOverlay = false;

  formTypes = Object.values(FormType);

  filterExpanded = true;

  /**
   * URL to the file that should be viewed in the modal
   */
  fileToView: string | null = null;

  /**
   * Form that should be viewed in the modal
   */
  formToView: HiveForm | null = null;

  /**
   * Whether the file modal is visible
   */
  isFileModalVisible = false;

  /**
   * Whether the PDF is currently in edit mode
   */
  pdfEditMode = false;

  /**
   * Cache for storing file URLs to prevent multiple downloads
   */
  fileCache: Map<number, string> = new Map();

  statuses = [
    {
      label: this.translate.instant('formComponent.statuses.completed'),
      value: true
    },
    {
      label: this.translate.instant('formComponent.statuses.open'),
      value: false
    }
  ];

  private subscription = new Subscription();

  currentUser: User | null = null;

  constructor(
    private readonly messageCenterService: MessageCenterService,
    private readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly pdfService: PdfService,
    private readonly authService: AuthService,
    private readonly pdfViewerService: NgxExtendedPdfViewerService
  ) {
    this.route.url.subscribe((url) => {
      url.forEach((segment) => {
        if (segment.path === 'ticket') {
          this.showTechnicianColumn = true;
        }
      });
    });
  }

  ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();
    this.filterExpanded = !this.smallTable;

    if (this.ticket.forms && !this.showSelectedFormsOnly) {
      this.forms = this.ticket.forms;
    }

    if (this.ticket.forms && this.selectedForms && this.showSelectedFormsOnly) {
      this.forms = this.selectedForms;
    }

    this.forms = this.forms.map((form) => ({
      ...form,
      key: this.getKey(form),
      showTechnicianCalendar: this.showCalendar(form),
      status:
        form.installationIdmLuftFormId !== null ||
        form.installationIdmSwFormId !== null ||
        form.sgcMaintenanceIdmFormId !== null ||
        form.serviceReportFormId !== null
    }));
  }

  getKey(form: HiveForm): string {
    if (form.id) {
      return form.id.toString();
    }

    return `${form.id || 'new'}-${form.customerDevice?.device?.title || 'unknown'}-${form.type || 'unknown'}-${form.createdAt || 'unknown'}`;
  }

  ngOnDestroy(): void {
    // Revoke all object URLs to prevent memory leaks
    this.fileCache.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    this.fileCache.clear();
    this.subscription.unsubscribe();
  }

  showCalendar(form: HiveForm): string | null {
    let retVal = '';
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    if (form.appointments && form.appointments.length > 0) {
      form.appointments.forEach((appointment) => {
        if (appointment.id !== Number(this.currentAppointmentId)) {
          const startDate = new Date(appointment.start).toLocaleString(
            'de-DE',
            options
          );

          retVal += `${startDate} - ${appointment.title}\n`;
        }
      });
    }

    return retVal;
  }

  displayDate(date: Date | string | null | undefined): string {
    if (!date) {
      return '';
    }

    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedForms']) {
      this.selectedForms = this.selectedForms.map((form) => ({
        ...form,
        key: this.getKey(form),
        showTechnicianCalendar: this.showCalendar(form)
      }));
    }
    if (changes['showSelectedFormsOnly']) {
      if (this.ticket.forms && !this.showSelectedFormsOnly) {
        this.forms = this.ticket.forms;
        this.forms = this.forms.map((form) => ({
          ...form,
          key: this.getKey(form),
          showTechnicianCalendar: this.showCalendar(form)
        }));
      }

      if (
        this.ticket.forms &&
        this.selectedForms &&
        this.showSelectedFormsOnly
      ) {
        this.forms = this.selectedForms;
        this.forms = this.forms.map((form) => ({
          ...form,
          key: this.getKey(form),
          showTechnicianCalendar: this.showCalendar(form)
        }));
      }
    }
  }

  createForm(): void {
    this.handleCreateForm.emit();
  }

  async editForm(form: HiveForm): Promise<void> {
    if (form.deadline) {
      form.deadline = new Date(form.deadline);
    }
    const now = new Date();
    if (form.appointments && form.appointments.length > 0) {
      const appointmentStart = new Date(form.appointments[0]?.start);

      if (appointmentStart <= now) {
        const update = await new Promise<boolean>((resolve) => {
          this.messageCenterService.confirm(
            this.translate.instant(
              'calendarComponent.appointment.editAppointment.editWarning.title'
            ),
            this.translate.instant(
              'calendarComponent.appointment.editAppointment.editWarning.summary'
            ),
            () => {
              resolve(true);
            },
            () => {
              resolve(false);
            }
          );
        });
        if (update) {
          this.handleUpdateForm.emit(form);
        }
      } else {
        this.handleUpdateForm.emit(form);
      }
    } else {
      this.handleUpdateForm.emit(form);
    }
  }

  private fetchPdf(formId: number): Observable<string> {
    const cached = this.fileCache.get(formId);

    if (cached) {
      return new Observable((observer) => {
        observer.next(cached);
        observer.complete();
      });
    }

    return this.pdfService.downloadFormPdf(formId).pipe(
      first(),
      map((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        this.fileCache.set(formId, url);

        return url;
      })
    );
  }

  /**
   * Downloads the PDF of the given form
   */
  downloadPdf(form: HiveForm): void {
    if (!form.id || !form.completedAt) {
      console.warn('Form ID or completedAt date is missing on Form', form);

      return;
    }

    const ticketType = this.translate.instant(
      `ticketComponent.ticketChips.${this.ticket.ticketCategoryType}`
    );
    const manufacturer = form.customerDevice?.device?.manufacturer?.name || '';
    const fileName = `${ticketType}`;
    const customerName = this.ticket.customer?.name || '';
    const workDate =
      moment(form.appointments?.[0]?.end).format('DDMMYYYY') ||
      moment(new Date()).format('DDMMYYYY');

    // downloadUrl function to download the PDF
    const downloadUrl = (url: string) => {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${workDate}_${fileName}_${manufacturer}_${customerName}_${form.type}_${form.id}_ticket_${this.ticket.ticketNumber}.pdf`;
      a.click();
    };

    // Download the PDF and cache the URL
    this.subscription.add(
      this.fetchPdf(form.id).subscribe((url: string) => {
        downloadUrl(url);
      })
    );
  }

  /**
   * Returns the file name for the given form
   */
  getFileName(): string {
    if (!this.formToView) {
      return '';
    }

    const ticketType = this.translate.instant(
      `ticketComponent.ticketChips.${this.ticket.ticketCategoryType}`
    );
    const manufacturer =
      this.formToView.customerDevice?.device?.manufacturer?.name || '';
    const fileName = `${ticketType}`;
    const customerName = this.ticket.customer?.name || '';
    const workDate =
      moment(this.formToView.appointments?.[0]?.end).format('DDMMYYYY') ||
      moment(new Date()).format('DDMMYYYY');

    return `${workDate}_${fileName}_${manufacturer}_${customerName}_${this.formToView.type}_${this.formToView.id}_ticket_${this.ticket.ticketNumber}.pdf`;
  }

  /**
   * Opens the PDF viewer for the given form
   */
  viewPdf(form: HiveForm): void {
    if (!form.id) {
      return;
    }

    // Set the PDF to be in view mode
    this.pdfEditMode = false;

    // Show the modal
    this.isFileModalVisible = true;

    // Download the PDF and cache the URL
    this.subscription.add(
      this.fetchPdf(form.id).subscribe((url: string) => {
        this.fileToView = url;
      })
    );
  }

  /**
   * Opens the PDF viewer in editMode for the given form
   */
  editPdf(form: HiveForm): void {
    if (!form || !form.file) {
      return;
    }

    // Set the PDF to be in edit mode
    this.pdfEditMode = true;
    this.formToView = form;
    // Show the modal
    this.isFileModalVisible = true;
    this.subscription.add(
      this.pdfService.updateFileLock(form.file.id).subscribe({
        next: (updatedFile: FileDto) => {
          if (updatedFile) {
            const formId = form.id;
            // Download the PDF and cache the URL
            if (formId) {
              this.fetchPdf(formId).subscribe((url: string) => {
                this.fileToView = url;
              });
            }
          }
        }
      })
    );
  }

  hidePdfViewer(): void {
    if (this.fileToView) {
      this.fileToView = null;
    }

    this.isFileModalVisible = false;
  }

  async deleteForm(form: HiveForm): Promise<void> {
    const deleteForm = await this.deleteConfirmation(form);
    if (deleteForm) {
      this.forms = this.forms.filter(
        (formToDelete) => formToDelete.id !== form.id
      );
      this.handleDeleteForm.emit(form);
    }
  }

  deleteConfirmation(form: HiveForm): Promise<boolean> {
    let formTypeTranslation = this.translate.instant(
      `formComponent.attributes.formTypes.${form.type}`
    );

    formTypeTranslation =
      formTypeTranslation && formTypeTranslation.length > 0
        ? formTypeTranslation
        : '';

    let device = form.customerDevice?.device?.title;
    device = device && device.length > 0 ? device : '';

    return new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('formComponent.form.delete.header'),
        this.translate.instant('formComponent.form.delete.message', {
          device,
          form: formTypeTranslation
        }),
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  filterApplied(filter: TableFilter): void {
    this.activeFilter = filter.id;

    if (this.formTable) {
      this.formTable.filters = structuredClone(
        filter.filterData
      ) as Table['filters'];
      this.formTable._filter();
    }
  }

  clearFilters(): void {
    if (this.formTable) {
      this.formTable.clearFilterValues();
      this.formTable.reset();
      clearTableMemoryFilters('form-table-memory');
    }

    this.activeFilter = null;
  }

  saveFilterClicked(filterName: string): void {
    if (!this.formTable) {
      throw new Error('Table element not found');
    }

    const tableFilter = new TableFilter();

    tableFilter.filterData = structuredClone(this.formTable?.filters);
    tableFilter.table = 'ticket';
    tableFilter.filterName = filterName;

    this.appFilter?.createFilter(tableFilter);
  }

  onSelectedFormsChange(event: HiveForm[]): void {
    this.selectedForms = event;
    this.selectedFormsChange.emit(this.selectedForms);
  }

  onDeadlineChange(deadline: Date, form: HiveForm): void {
    form.deadline = deadline;
    this.deadlineChange.emit(form);
  }

  /**
   * Exports the current PDF document by replacing an existing file with the updated version.
   * This involves fetching the document as a Blob, converting it to Base64,
   * and updating the corresponding file in the system.
   *
   * @returns {Promise<void>} A promise that resolves once the export process completes.
   */
  public async export(): Promise<void> {
    // Fetch the current document as a Blob from the PDF viewer service
    this.blob = await this.pdfViewerService.getCurrentDocumentAsBlob();

    if (this.blob) {
      // Convert the Blob to Base64 format
      this.subscription.add(
        blobToBase64(this.blob).subscribe((base64) => {
          // Extract necessary IDs from the current form
          const fileIdToUpdate = this.formToView?.fileId;
          const ticketId = this.formToView?.ticketId;
          const formId = this.formToView?.id;

          // Ensure all required IDs and the Blob are available
          if (ticketId && fileIdToUpdate && formId) {
            this.subscription.add(
              this.pdfService
                .replaceFile(
                  fileIdToUpdate, // ID of the file to be replaced
                  this.getFileName(), // Generate a dynamic file name
                  base64 // Base64 encoded Blob
                )
                .subscribe((file) => {
                  if (file) {
                    // Clear all object URLs from the cache
                    this.fileCache.forEach((url) => {
                      URL.revokeObjectURL(url);
                    });
                    this.fileCache.clear();

                    // Update the form in both the ticket and local forms arrays
                    if (this.ticket.forms) {
                      const ticketFormIndex = this.ticket.forms.findIndex(
                        (formToFind) => formToFind.id === formId
                      );
                      const localFormIndex = this.forms.findIndex(
                        (formToFind) => formToFind.id === formId
                      );

                      if (ticketFormIndex > -1 && localFormIndex > -1) {
                        // Close the modal and reset states
                        this.isFileModalVisible = false;
                        this.pdfEditMode = false;

                        // Emit the updated ticket object
                        this.ticketUpdated.emit(this.ticket);
                      }
                    }
                  }
                })
            );
          } else {
            console.warn('Missing required IDs or Blob for file replacement');
          }
        })
      );
    } else {
      console.warn('No Blob found. Cannot export the document.');
    }
  }

  /**
   * Cancels the edit operation and unlocks the form file.
   * This method clears the file cache, form, and file states.
   *
   * @returns {Promise<void>} A promise that resolves when the edit operation is canceled.
   */
  async cancelEdit(): Promise<void> {
    const cancel = await this.cancelFormEdit();

    if (this.formToView && cancel) {
      const fileId = this.formToView.file?.id;
      if (fileId) {
        this.subscription.add(
          this.pdfService.updateFileUnlock(fileId).subscribe({
            next: (updatedFile: FileDto) => {
              if (updatedFile) {
                // Clear the file cache
                this.fileCache.forEach((url) => {
                  URL.revokeObjectURL(url);
                });
                this.fileCache.clear();

                // Clear the form and file states
                this.formToView = null;
                this.fileToView = null;
                this.isFileModalVisible = false;
              }
            }
          })
        );
      }
    }
  }

  /**
   * Prompts the user to confirm the cancellation of editing the form pdf.
   *
   * @returns {Promise<boolean>} A promise that resolves to true if the user confirms the cancellation, false otherwise.
   */
  async cancelFormEdit(): Promise<boolean> {
    const cancel = await new Promise<boolean>((resolve) => {
      this.messageCenterService.confirm(
        this.translate.instant('formComponent.formLocked.cancelModal.header'),
        this.translate.instant('formComponent.formLocked.cancelModal.message'),
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });

    return cancel;
  }

  /**
   * Unlocks the PDF file associated with the given form.
   * This method updates the file lock status and shows a toast message based on the result.
   *
   * @param {HiveForm} form - The form containing the file to unlock.
   * @returns {void}
   */
  unlockPdf(form: HiveForm): void {
    if (form.fileId) {
      this.isLoading = true;
      this.subscription.add(
        this.pdfService.updateFileUnlock(form.fileId).subscribe({
          next: (updatedFile: FileDto) => {
            if (updatedFile) {
              // Find the index of the form with the matching fileId
              const index = this.forms.findIndex(
                (formToFind) => formToFind.fileId === form.fileId
              );
              if (index > -1) {
                const formItem = this.forms[index];
                if (formItem?.file) {
                  // Clear the lock information on the file
                  formItem.file.fileLockedAt = null;
                  formItem.file.fileLockedById = null;
                }
                // Update the form in the forms array
                this.forms[index] = formItem;
              }
              // Show a success toast message
              this.showUnlockToast('success');
            } else {
              // Show an error toast message if the file was not updated
              this.showUnlockToast('error');
            }
            // Set the loading state to false
            this.isLoading = false;
          },
          error: () => {
            this.showUnlockToast('error');
            this.isLoading = false;
          }
        })
      );
    }
  }

  /**
   * Shows a toast message indicating the result of the unlock operation.
   *
   * @param {Severity} severity - The severity level of the toast message.
   * @returns {void}
   */
  showUnlockToast(severity: Severity): void {
    this.messageCenterService.showToast(
      this.translate.instant(
        `formComponent.formLocked.unlockToast.${severity}.summary`
      ),
      this.translate.instant(
        `formComponent.formLocked.unlockToast.${severity}.details`
      ),
      severity
    );
  }

  /**
   * Checks if the form file is locked by the current user.
   *
   * @param {HiveForm} form - The form to check.
   * @returns {boolean} True if the form file is locked by the current user, false otherwise.
   */
  fileIsLockedByCurrentUser(form: HiveForm): boolean {
    if (form.file) {
      return (
        form.file.fileLockedAt !== null &&
        form.file.fileLockedById === this.currentUser?.id
      );
    }

    return false;
  }

  /**
   * Checks if the form file is locked by another user.
   *
   * @param {HiveForm} form - The form to check.
   * @returns {boolean} True if the form file is locked by another user, false otherwise.
   */
  fileIsLockedByOtherUser(form: HiveForm): boolean {
    if (form.file) {
      return (
        form.file.fileLockedAt !== null &&
        form.file.fileLockedById !== this.currentUser?.id
      );
    }

    return false;
  }

  /**
   * Checks if the user has permission to perform the specified action on a file.
   *
   * @param {AppAction} action - The action to check permissions for.
   * @returns {boolean} True if the user has permission, false otherwise.
   */
  $can(action: AppAction): boolean {
    return this.authService.$can(action, 'File');
  }

  /**
   * Checks if the current user has permission to unlock the file.
   *
   * @returns {boolean} True if the user has permission to unlock the file, false otherwise.
   */
  hasPermissionToUnlock(): boolean {
    if (!this.currentUser) {
      return false;
    }

    return hasPermissionToUnlockFiles(this.currentUser);
  }
}
