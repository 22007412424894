import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, switchMap, timer } from 'rxjs';
import { LoadingService } from 'src/app/services/utils/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent implements OnInit, OnDestroy {
  isLoading = false;

  loadingAnimation = false;

  private loadingSubscription: Subscription | null = null;

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingSubscription = this.loadingService.loading$
      .pipe(
        switchMap((loading) => {
          this.isLoading = loading;
          if (loading) {
            return timer(100).pipe(
              switchMap(() => {
                this.loadingAnimation = true;

                return timer(0); // Continue to emit after 100ms
              })
            );
          }
          this.loadingAnimation = false;

          return [];
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}
