/**
 * Clears the filter values in the local storage for the specified table memory.
 *
 * @param {string} tableMemoryName - The name of the table memory in local storage.
 * @returns {void}
 */
export function clearTableMemoryFilters(tableMemoryName: string): void {
  const memory = localStorage.getItem(tableMemoryName);

  if (!memory) {
    return; // Exit if no table data was found in local storage
  }

  try {
    // Parse the memory object
    const { columnWidths, first, filters, rows, tableWidth } =
      JSON.parse(memory);

    if (filters && typeof filters === 'object') {
      // Iterate over each filter and clear the 'value' property if it exists
      Object.keys(filters).forEach((key) => {
        if (
          Reflect.apply(Object.prototype.hasOwnProperty, filters[key], [
            'value'
          ])
        ) {
          filters[key].value = null;
        }
      });
    }

    // Update local storage with cleared filters
    localStorage.setItem(
      tableMemoryName,
      JSON.stringify({ columnWidths, filters, first, rows, tableWidth })
    );
  } catch (error) {
    console.error(
      `Failed to parse or update table memory for table: ${tableMemoryName}:`,
      error
    );
  }
}
